import React, { useEffect, useRef, useState } from 'react';
import './App.css';
import { AppBar, Box, Button, Checkbox, CircularProgress, createMuiTheme, Divider, Fab, FormHelperText, Grid, GridList, GridListTile, IconButton, Input, Link, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, makeStyles, Modal, Slider, Snackbar, TextareaAutosize, TextField, ThemeProvider, Toolbar, useMediaQuery } from '@material-ui/core'
import { Add, AdjustOutlined, ArrowRight, ArrowRightOutlined, BusinessCenter, CalendarTodayOutlined, CheckCircleOutlineSharp, CloudOffOutlined, CloudOutlined, KeyboardArrowLeft, KeyboardArrowRightOutlined, Label, LoopOutlined, MoreVert, NetworkWifiRounded, NotificationsNone, NotificationsNoneSharp, NotificationsOutlined, PanoramaFishEyeSharp, SearchOutlined, SentimentSatisfied, UpdateOutlined, VisibilityOutlined } from '@material-ui/icons'
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns'; 
import { de } from 'date-fns/locale';
import { blue, teal } from '@material-ui/core/colors';
import { DateTime } from 'luxon';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/analytics'
import 'firebase/firestore'
import { SnackbarProvider, useSnackbar } from 'notistack';
import AppHeader from './AppHeader';
import AppDivider from './AppDivider';
import MobileHeader from './MobileHeader';

const LS_LAST_UID = "LAST_UID";
const LOCAL_UID = "LOCAL_UID";
let db = null;
let localProjects = [];
let localTasks = [];
const LOGIN_MODE = 0;
const REGISTRATION_MODE = 1;
const FORGOT_PASSWORD_MODE = 2;

const TEST_TASK_DATA = {
  projects: [
    {
      id: 1,
      title: "Tutorial",
      tasks: [
        {
          id: 1,
          title: "<- Set task done",
          desc: null,
          size: 3,
          prio: 3,
          series: 0,
          remindDate: null,
          dueDate: null
        },{
          id: 2,
          title: "Create a test task",
          desc: null,
          size: 3,
          prio: 3,
          series: 0,
          remindDate: null,
          dueDate: null
        },{
          id: 3,
          title: "Update a test task",
          desc: null,
          size: 3,
          prio: 3,
          series: 0,
          remindDate: null,
          dueDate: null
        },{
          id: 4,
          title: "Delete your test task",
          desc: null,
          size: 3,
          prio: 3,
          series: 0,
          remindDate: null,
          dueDate: null
        },{
          id: 5,
          title: "Create your first project",
          desc: null,
          size: 3,
          prio: 3,
          series: 0,
          remindDate: null,
          dueDate: null
        },{
          id: 6,
          title: "Delete tutorial project",
          desc: null,
          size: 3,
          prio: 3,
          series: 0,
          remindDate: null,
          dueDate: null
        }
      ]
    }
  ]
};

const useStyles = makeStyles((theme) => ({
  gridListContainer: {
    marginBottom: 54,
    paddingRight: 50
  },
  gridList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  gridListTile: {
    height: "100% !important",
    minWidth: 380
  },
  checkItem: {
    minWidth: 44,
  },
  checkBoxItem: {
    marginLeft: 0,
    color: "rgba(255, 255, 255, 0.24) !important",
  },
  mobileCheckBoxItem: {
    marginLeft: 0,
    color: "rgba(255, 255, 255, 0.2) !important",
    fontSize: "1.8rem",
  },
  taskItem: {
    padding: 0,
  },
  listDivider: {
    marginLeft: 40,
    backgroundColor: "rgba(200, 200, 255, 0.42) !important",
  },
  mainDivider: {
    backgroundColor: "rgba(200, 200, 255, 0.42) !important",
    marginLeft: 50,
    marginRight: 50,
  },
  taskListHeader: {
    margin: "15px 0px 15px 53px",
    color: "rgba(255, 255, 255, 0.6)",
    fontSize: 18,
  },
  circProgress: {
    marginTop: 4,
    color: "rgba(255, 255, 255, 0.4) !important",
  },
  circProgressRed: {
    marginTop: 4,
    color: "rgba(207, 106, 106, 0.6) !important",
  },
  remind: {
    marginTop: 4,
    marginRight: -4,
    color: "rgba(255, 255, 255, 0.4) !important",
  },
  remindRed: {
    marginTop: 4,
    marginRight: -4,
    color: "rgba(207, 106, 106, 0.6) !important",
  },
  taskAddIcon: {
    margin: "2px 0px 0px 0px",
  },
  noWrap: {
    flexWrap: 'nowrap',
  },
  taskList: {
    marginLeft: 10,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: "#101021",
    fontFamily: "Roboto",
    color: "rgba(255, 255, 255, 0.7)",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3, 4, 2, 3),
    outline: "none",
    width: 600,
  },
  paperProject: {
    backgroundColor: "#101021",
    fontFamily: "Roboto",
    color: "rgba(255, 255, 255, 0.7)",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3, 4, 2, 3),
    outline: "none",
    width: 400,
  },
  modalHeadline: {
    fontSize: 20,
    paddingBottom: 8,
    paddingLeft: 3,
  },
  editValueText: {
    color: "#BB86FC",
  },
  editAreaSliderLabel: {
    paddingTop: "0px !important",
    paddingBottom: "0px !important",
  },
  editSlider: {
    paddingTop: "0px !important",
    paddingBottom: "0px !important",
  },
  editTaskRightContainer: {
    paddingLeft: 20,
    paddingTop: 20,
  },
  editTaskBottomContainer: {
    paddingTop: 20,
  },
  modalButtonBar: {
    marginTop: 15,
  },
  deleteButton: {
    color: "#CF6A6A",
  },
  hidden: {
    visibility: "hidden"
  },
  displayNone: {
    display: "none"
  },
  taskText: {
    fontSize: "0.9rem !important",
    color: "white"
  },
  taskTextDone: {
    fontSize: "0.9rem !important",
    color: "white",
    textDecoration: "line-through"
  },
  nextTaskIcon: {
    marginLeft: -10
  },
  header: {
    padding: "24px 35px 0px 50px",
  },
  searchInput: {
    marginTop: 8,
  },
  searchClear: {
    fontSize: "1rem !important"
  },
  headerTitle: {
    flexGrow: 1,
  },
  mobileHeader: {
    backgroundColor: "#101021 !important",
    borderBottom: "1px rgba(200, 200, 255, 0.42) solid",
  },
  offset: theme.mixins.toolbar,
  appBar: {
    top: 'auto',
    bottom: 0,
    backgroundColor: "#101021 !important",
    borderTop: "1px rgba(200, 200, 255, 0.42) solid",
  },
  grow: {
    flexGrow: 1,
  },
  fabButton: {
    position: 'absolute',
    zIndex: 1,
    top: -30,
    left: 0,
    right: 0,
    margin: '0 auto',
    color: theme.palette.primary.main ,
    border: '1px black solid',
    borderColor: "rgba(200, 200, 255, 0.42) !important",
    backgroundColor: "#000000 !important",
  },
  mobileMail: {
    textOverflow: "ellipsis",
    maxWidth: "40%",
    overflow: "hidden",
  },
  mobileList: {
    marginBottom: 75,
  },
  taskSecondaryText: {
    color: "rgba(255, 255, 255, 0.7)",
  },
  logo: {
    width: 25,
    height: 25,
    margin: "10px 14px 6px -1px",
  },
  mobileSearchInput: {
    width: "80%",
  }
}));

const firebaseConfig = {
  apiKey: "AIzaSyCUQRWYc_pIuvTK5mLS8rJt1DOFUERGOLQ",
  authDomain: "cuvell21.firebaseapp.com",
  databaseURL: "https://cuvell21.firebaseio.com",
  projectId: "cuvell21",
  storageBucket: "cuvell21.appspot.com",
  messagingSenderId: "370546423651",
  appId: "1:370546423651:web:0ad25b28dd61cb199f4556",
  measurementId: "G-BNMVN32088"
};

function App(props) {

  const { enqueueSnackbar } = useSnackbar();

  const classes = useStyles();
  const [projects, setProjects] = useState(null);
  const [tasks, setTasks] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [addEditOpen, setAddEditOpen] = useState(false);
  const [addEditPrioText, setAddEditPrioText] = useState('MID');
  const [addEditSizeText, setAddEditSizeText] = useState('M');
  const [addEditSeriesText, setAddEditSeriesText] = useState('NONE');
  const [addEditTask, setAddEditTask] = useState({});

  const [editProjectMode, setEditProjectMode] = useState(false);
  const [addEditProjectOpen, setAddEditProjectOpen] = useState(false);
  const [addEditProject, setAddEditProject] = useState({});

  const [focusedTask, setFocusedTask] = useState(null);
  
  const [auth, setAuth] = useState(null);

  const [signInOpen, setSignInOpen] = useState(false);
  const [signInEmail, setSignInEmail] = useState("");
  const [signInPass, setSignInPass] = useState("");
  const [signInPassRepeat, setSignInPassRepeat] = useState("");
  const [signInErrorMsg, setSignInErrorMsg] = useState(null);
  const [registerMode, setRegisterMode] = useState(0);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [showCompletedTasks, setShowCompletedTasks] = useState(false);
  const [addMobileProjectSelection, setAddMobileProjectSelection] = useState(false);

  const rootRef = useRef(null);

  const handleAddOpen = (e) => {
    e.stopPropagation();
    setAddEditTask({
      title: "",
      desc: "",
      size: 3,
      prio: 3,
      series: 0,
      remindDate: null,
      dueDate: null,
      projectId: e.currentTarget.id,
      uid: getUid(auth)
    });
    setAddEditPrioText(getAddEditPrioText(3));
    setAddEditSizeText(getAddEditSizeText(3));
    setAddEditSeriesText(getAddEditSeriesText(0));
    setEditMode(false);

    setAddEditOpen(true);

  };

  const taskByID = (id) => {
    try {
      return tasks.find(task => task.id === id).data();
    } catch (notFound) {
      return null;
    }
  };

  const projectByID = (id) => {
    try {
      return projects.find(project => project.id === id).data();
    } catch (notFound) {
      return null;
    }
  };

  const handleEditOpen = (e) => {
    let task = taskByID(e.currentTarget.id);
    console.log(task);
    if (task) {
      if (task.dueDate && task.dueDate.seconds) {
        task.dueDate = new Date(task.dueDate.seconds * 1000);
      }
      if (task.remindDate && task.remindDate.seconds) {
        task.remindDate = new Date(task.remindDate.seconds * 1000);
      }
      e.stopPropagation();
      setAddEditTask({...task, id: e.currentTarget.id});
      setAddEditPrioText(getAddEditPrioText(task.prio));
      setAddEditSizeText(getAddEditSizeText(task.size));
      setAddEditSeriesText(getAddEditSeriesText(task.series));
      setEditMode(true);
      setAddEditOpen(true);
    }
  };

  const handleAddEditClose = () => {
    setAddEditOpen(false);
  };

  const getNewIdx = (objs) => {
    let maxIdx = 0;
    if (objs) {
      for (let i=0; i<objs.length; i++) {
        maxIdx = Math.max(objs[i].data().idx, maxIdx);
      }
    }
    return ++maxIdx;
  }

  const handleAddEditExecute = (e) => {
    e.preventDefault();
    if (editMode) {
      // TODO update task
      let docId = addEditTask.id;
      delete addEditTask["id"];
      db.collection("tasks").doc(docId).set({...addEditTask, updated: new Date().getTime()}).catch((error) => {
        console.log("Error update task:", error);
      }).then(() => {
        if (auth) {
          loadTasks();
        }
      });
    } else {
      db.collection("tasks").add({...addEditTask, idx: getNewIdx(tasks), created: new Date().getTime(), updated: new Date().getTime()}).catch((error) => {
        console.log("Error add task:", error);
      }).then(() => {
        if (auth) {
          loadTasks();
        }
      });
    }
    if (!auth) {
      setTimeout(loadTasks, 200);
    }
    setAddEditOpen(false);
    setAddMobileProjectSelection(false);
  };
  
  const handleAddEditDelete = () => {
    db.collection("tasks").doc(addEditTask.id).delete().catch((error) => {
      console.log("Error delete task:", error);
    }).then(() => {
      if (auth) {
        loadTasks();
      }
    });
    if (!auth) {
      setTimeout(loadTasks, 200);
    }
    setAddEditOpen(false);
  };
  
  const handleAddProjectOpen = (e) => {
    setAddEditProject({
      title: "",
      desc: "",
      uid: getUid(auth)
    });
    setEditProjectMode(false);
    setAddEditProjectOpen(true);
  };
  
  const handleEditProjectOpen = (e) => {
    let project = projectByID(e.currentTarget.id);
    console.log(project);
    if (project) {
      e.stopPropagation();
      setAddEditProject({...project, id: e.currentTarget.id});
      setEditProjectMode(true);
      setAddEditProjectOpen(true);
    }
  };
  
  const handleAddEditProjectClose = () => {
    setAddEditProjectOpen(false);
  };
  
  const handleAddEditProjectExecute = (e) => {
    e.preventDefault();
    if (editProjectMode) {
      let docId = addEditProject.id;
      delete addEditProject["id"];
      db.collection("projects").doc(docId).set({...addEditProject, updated: new Date().getTime()})
      .then(() => {
        if (auth) {
          loadProjects();
        }
      })
      .catch((error) => {
        console.log("Error update project:", error);
      });
    } else {
      db.collection("projects").add({...addEditProject, idx: getNewIdx(projects), created: new Date().getTime(), updated: new Date().getTime()})
      .then(function(docRef) {
        if (auth) {
          loadProjects();
        }
      })
      .catch((error) => {
        console.log("Error add project:", error);
      });
    }
    if (!auth) {
      setTimeout(loadProjects, 200);
    }
    setAddEditProjectOpen(false);
  };

  const handleAddEditProjectDelete = () => {
    let batch = db.batch();
    tasks.forEach(task => {
      if (task.data().projectId === addEditProject.id) {
        batch.delete(db.collection("tasks").doc(task.id));
      }
    });
    batch.delete(db.collection("projects").doc(addEditProject.id));
    batch.commit().then(function () {
      if (auth) {
        loadTasks();
        loadProjects();
      }
    }).catch(function(error) {
      console.log("Error delete project:", error);
    });
    if (!auth) {
      setTimeout(loadProjects, 200);
      setTimeout(loadTasks, 210);
    }
    setAddEditProjectOpen(false);
  };

  const handleSignInSubmit = (e) => {
    e.preventDefault();
    switch (registerMode) {
      case REGISTRATION_MODE:
        handleRegister();
        break;
      case FORGOT_PASSWORD_MODE:
        handleResetPassword();
        break;
      default:
        handleSignIn();
        break;
    }
  }

  const handleRemindOnChange = (value) => {
    setAddEditTask({...addEditTask, remindDate: value});
  };
  
  const handleDueOnChange = (value) => {
    setAddEditTask({...addEditTask, dueDate: value});
  };
  
  const handleAddEditChangeName = (e) => {
    setAddEditTask({...addEditTask, title: e.target.value});
  };
  
  const handleAddEditChangeDesc = (e) => {
    setAddEditTask({...addEditTask, desc: e.target.value});
  };
  
  const handleAddEditProjectChangeName = (e) => {
    setAddEditProject({...addEditProject, title: e.target.value});
  };
  
  const handleAddEditProjectChangeDesc = (e) => {
    setAddEditProject({...addEditProject, desc: e.target.value});
  };

  const handlePrioOnChange = (e, prio) => {
    setAddEditTask({...addEditTask, prio: prio});
    setAddEditPrioText(getAddEditPrioText(prio));
  };

  const handleSizeOnChange = (e, size) => {
    setAddEditTask({...addEditTask, size: size});
    setAddEditSizeText(getAddEditSizeText(size));
  };

  const handleSeriesOnChange = (e, series) => {
    setAddEditTask({...addEditTask, series: series});
    setAddEditSeriesText(getAddEditSeriesText(series));
  };

  const handleTaskMouseOver = (e) => {
    setFocusedTask(e.currentTarget.id);
  };

  const handleTaskMouseOut = (e) => {
    setFocusedTask(null);
  };

  const calculateNextShownDate = (task) => {
    if (task.series > 0) {
      let calcNextDate = (current) => {
        return current.plus({days: 1});
      };
      switch (task.series) {
        case 2:
          calcNextDate = (current) => {
            do {
              current = current.plus({days: 1});
            } while (current.weekday !== 6 && current.weekday !== 7);
            return current;
          };
          break;
        case 3:
          calcNextDate = (current) => {
            return current.plus({weeks: 1});
          };
          break;
        case 4:
          calcNextDate = (current) => {
            return current.plus({weeks: 2});
          };
          break;
        case 5:
          calcNextDate = (current) => {
            return current.plus({months: 1});
          };
          break;
        case 6:
          calcNextDate = (current) => {
            return current.plus({months: 3});
          };
          break;
        case 7:
          calcNextDate = (current) => {
            return current.plus({months: 6});
          };
          break;
        case 8:
          calcNextDate = (current) => {
            return current.plus({months: 12});
          };
          break;
        default:
          calcNextDate = (current) => {
            return current.plus({days: 1});
          };
          break;          
      }
      let lastRemindDate = DateTime.fromMillis(task.created);
      if (task.remindDate) {
        lastRemindDate = DateTime.fromMillis(task.remindDate.seconds * 1000);
      }
      let currentDate = DateTime.local();
      let safeCounter = 0;
      while (lastRemindDate<currentDate) {
        lastRemindDate = calcNextDate(lastRemindDate);
        safeCounter++;
        if (safeCounter > 100000) {
          return null;
        }
      }
      return lastRemindDate.toJSDate();
    }
    return null;
  };
  
  const handleTaskDone = (e) => {
    e.stopPropagation();
    const taskId = e.currentTarget.parentElement.parentElement.id;
    if (taskId) {
      let task = taskByID(taskId);
      if (task) {
        // check series
        let nextShownDate = calculateNextShownDate(task);
        db.collection("tasks").doc(taskId).set({...task, done: !isDone(task), nextShownDate: nextShownDate}).catch((error) => {
          console.log("Error update task:", error);
        }).then(() => {
          loadTasks();
        });
      }
    }
  };

  const isDone = (task) => {
    let showNextDate = false;
    if (task.nextShownDate && (task.nextShownDate.seconds * 1000) < new Date().getTime()) {
      showNextDate = true;
    }
    return task.done && !showNextDate;
  }

  const handleSignOut = () => {
    firebase.auth().signOut();
  };
  
  const handleRegister = () => {
    if (signInPass !== signInPassRepeat) {
      setSignInErrorMsg("The passwords do not match!");
      return;
    }
    if (!acceptTerms) {
      setSignInErrorMsg("Please accept our privacy policies, terms & conditions!");
      return;
    }
    firebase.auth().createUserWithEmailAndPassword(signInEmail, signInPass).catch(function(error) {
      // Handle Errors here.
      var errorCode = error.code;
      var errorMessage = error.message;
      console.log(errorCode + ", " + errorMessage);
      setSignInErrorMsg(errorMessage);
    }).then(function(auth) {
      setSignInPass("");
      setSignInPassRepeat("");
      if (auth) {
        setAuth(auth);
        console.log(auth);
        handleSignInClose();
      }
    });
  };
  
  const handleSwitchToRegistration = () => {
    setSignInPass("");
    setSignInPassRepeat("");
    setSignInErrorMsg(null);
    setRegisterMode(REGISTRATION_MODE);
  };

  const handleForgotPasswordClick = () => {
    setSignInPass("");
    setSignInPassRepeat("");
    setSignInErrorMsg(null);
    setRegisterMode(FORGOT_PASSWORD_MODE);
  };

  const handleSwitchToSignIn = () => {
    setSignInPass("");
    setSignInPassRepeat("");
    setSignInErrorMsg(null);
    setRegisterMode(LOGIN_MODE);
  };

  const handleOpenSignIn = () => {
    setSignInPass("");
    setSignInPassRepeat("");
    setSignInErrorMsg(null);
    setRegisterMode(LOGIN_MODE);
    setSignInOpen(true);
  };

  const handleSignInClose = () => {
    setSignInOpen(false);
  };

  const handleSignInEmailChange = (e) => {
    setSignInEmail(e.target.value);
    setSignInErrorMsg(null);
  };
  
  const handleSignInPasswordChange = (e) => {
    setSignInPass(e.target.value);
    setSignInErrorMsg(null);
  };
  
  const handleSignInPasswordRepeatChange = (e) => {
    setSignInPassRepeat(e.target.value);
    setSignInErrorMsg(null);
  };

  const handleSignIn = () => {
    handleSignInClose();
    firebase.auth().signInWithEmailAndPassword(signInEmail, signInPass).catch(function(error) {
      // Handle Errors here.
      var errorCode = error.code;
      var errorMessage = error.message;
      console.log(errorCode + ", " + errorMessage);
      setSignInErrorMsg(errorMessage);
    }).then(function(auth) {
      setSignInPass("");
      if (auth) {
        setAuth(auth);
        console.log(auth);
      }
    });
  };

  const handleAcceptTerms = (e) => {
    setAcceptTerms(e.target.checked);
  }

  const handleResetPassword = () => {
    firebase.auth().sendPasswordResetEmail(signInEmail).catch(function(error) {
      // Handle Errors here.
      var errorCode = error.code;
      var errorMessage = error.message;
      console.log(errorCode + ", " + errorMessage);
      setSignInErrorMsg(errorMessage);
    }).then(function() {
      enqueueSnackbar('Password reset email was sent successfully.', { variant: 'success' } );
      setSignInErrorMsg(null);
      setRegisterMode(LOGIN_MODE);
    });
  }

  const handleOnChangeSearchValue = (e) => {
    setSearchValue(e.target.value);
  }

  const handleClearSearchValue = () => {
    setSearchValue("");
  }

  const getAddEditPrioText = (value) => {
    switch (value) {
      case 1: return "VERY LOW";
      case 2: return "LOW";
      case 4: return "HIGH";
      case 5: return "VERY HIGH";
      default: return "MID";
    }
  }
  
  const getAddEditSizeText = (value) => {
    switch (value) {
      case 1: return "XS";
      case 2: return "S";
      case 4: return "L";
      case 5: return "XL";
      default: return "M";
    }
  }
  
  const getAddEditSeriesText = (value) => {
    switch (value) {
      case 1: return "DAY";
      case 2: return "WEEKDAY";
      case 3: return "WEEK";
      case 4: return "2 WEEKS";
      case 5: return "MONTH";
      case 6: return "QUARTERLY";
      case 7: return "HALF-YEARLY";
      case 8: return "YEAR";
      default: return "NONE";
    }
  }

  const getUid = (user) => {
    let lastUid = localStorage.getItem(LS_LAST_UID);
    if (!lastUid) {
      lastUid = LOCAL_UID;
      localStorage.setItem(LS_LAST_UID, lastUid);
    }
    if (user && lastUid === LOCAL_UID) {
      // TODO
      localStorage.setItem(LS_LAST_UID, user.uid);
      return user ? user.uid : lastUid;
    } else {
      localStorage.setItem(LS_LAST_UID, user ? user.uid : lastUid);
      return user ? user.uid : lastUid;
    }
  }

  const loadProjects = (uid) => {
    uid = uid || getUid(auth);
    console.log("uid: " + uid);
    if (uid === LOCAL_UID) {
      localProjects = [];
    }
    // load data
    db.collection("projects").where("uid", "==", uid).orderBy("idx", "asc").get().then((querySnapshot) => {
      console.log(querySnapshot);
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        console.log(doc.id, " => ", doc.data());
        if (uid === LOCAL_UID) {
          localProjects.push(doc);
        }
      });
      setProjects(querySnapshot.docs);
    }).catch(function(error) {
        console.log("Error getting projects:", error);
    });
  }

  const loadTasks = (uid) => {
    uid = uid || getUid(auth);
    console.log("uid: " + uid);
    if (uid === LOCAL_UID) {
      localTasks = [];
    }
    // load data
    db.collection("tasks").where("uid", "==", uid).orderBy("idx", "asc").get().then((querySnapshot) => {
      console.log(querySnapshot);
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        console.log(doc.id, " => ", doc.data());
        if (uid === LOCAL_UID) {
          localTasks.push(doc);
        }
      });
      setTasks(sortTasks(querySnapshot.docs));
    }).catch(function(error) {
        console.log("Error getting tasks:", error);
    });
  }

  const sortTasks = (taskDocs) => {
    return taskDocs.sort((a, b) => calcRating(b) - calcRating(a));
  }

  const isFirstInProject = (tasks, taskId, projectId) => {
    if (projectId === null) {
      return tasks[0].id === taskId;
    }
    for (let t=0; t<tasks.length; t++) {
      if (tasks[t].data().projectId === projectId) {
        return tasks[t].id === taskId;
      }
    }
    return true;
  }

  const calcRating = (doc) => {
    let task = doc.data();
    if (isDone(task)) {
      return -100000;
    }
    let rating = task.prio * 10;
    rating += (6 - task.size) * 6;
    rating += Math.min(parseInt(((new Date().getTime() - task.created) / 1000.0 / 60.0 / 60.0 / 24.0) * 0.3), 20);
    if (task.remindDate && new Date().getTime() > new Date(task.remindDate.seconds * 1000).getTime()) {
      rating += 200;
    } else if (task.remindDate) {
      rating = 0;
    }
    if (task.dueDate && new Date().getTime() > new Date(task.dueDate.seconds * 1000).getTime()) {
      rating += 201;
    } else if (task.dueDate) {
      let daysRating = ((10 * task.size) - ((new Date(task.dueDate.seconds * 1000).getTime() - new Date().getTime()) / 1000.0 / 60.0 / 60.0 / 24.0));
      rating += Math.max(0, Math.min((10 * task.size), daysRating));
    }
    if (task.series > 0) {
      rating += 50;
    }
    console.log(task.title + " -> rating " + rating);
    return rating;
  }

  const copyDataFromLocalToCloud = (uid) => {
    let batch = db.batch();
    localProjects.forEach(doc => {
      console.log("add project: ", doc.id);
      batch.set(db.collection("projects").doc(doc.id), {...doc.data(), uid: uid});
    });
    localTasks.forEach(doc => {
      console.log("add task: ", doc.id);
      batch.set(db.collection("tasks").doc(doc.id), {...doc.data(), uid: uid});
    });
    batch.commit().then(function () {
      console.log("Batch update successful");
    }).catch(function(error) {
      console.log("Error batch update:", error);
    });
  }

  const getRegistrationModeTitle = () => {
    switch (registerMode) {
      case REGISTRATION_MODE:
        return 'Sign Up';
      case FORGOT_PASSWORD_MODE:
        return 'Forgot Password';
      default:
        return 'Log Into cuvell';
    } 
  }

  const checkShowTask = (task, idx, project) => {
    if (project === null || task.data().projectId === project.id) {
      if (!isDone(task.data()) || showCompletedTasks) {
        if (searchValue === "") {
          return true;
        } if (task.data().title.toLowerCase().indexOf(searchValue.toLowerCase()) > -1) {
          return true;
        } if (task.data().desc && task.data().desc.toLowerCase().indexOf(searchValue.toLowerCase()) > -1) {
          return true;
        }
      }
    }
    return false;
  }

  const handleShowCompletedTasks = () => {
    setShowCompletedTasks(true);
  }

  const handleHideCompletedTasks = () => {
    setShowCompletedTasks(false);
  }

  const calcDueDateCircle = (task) => {
    if (task.data().dueDate) {
      let daysDiff = DateTime.local().diff(DateTime.fromJSDate(new Date(task.data().dueDate.seconds * 1000)), 'days').toObject().days;
      let percent =  Math.min(100, Math.max(0, Math.round(100 - (daysDiff * -1 / (30 / 100)))));
      return percent;
    }
    return 0;
  }

  const isTaskOverDue = (task) => {
    if (task.data().dueDate) {
      return DateTime.local() > DateTime.fromJSDate(new Date(task.data().dueDate.seconds * 1000));
    }
    return false;
  }

  const isTaskOverDueRemind = (task) => {
    if (task.data().remindDate) {
      return DateTime.local() > DateTime.fromJSDate(new Date(task.data().remindDate.seconds * 1000));
    }
    return false;
  }

  const isOverReminderDate = (task) => {
    if (task.data().remindDate) {
      let currentDate = DateTime.local();
      let remindDate = DateTime.fromJSDate(new Date(task.data().remindDate.seconds * 1000));
      return currentDate > remindDate;
    }
    return false;
  }

  const handleAddMobile = () => {
    setAddMobileProjectSelection(true);
  }

  const handleAddMobileClose = () => {
    setAddMobileProjectSelection(false);
  }

  useEffect(() => {
    console.log("init firebase");
    firebase.initializeApp(firebaseConfig);
    firebase.analytics();
    db = firebase.firestore();
    db.enablePersistence().catch((err) => {
      if (err.code === 'failed-precondition') {
          // Multiple tabs open, persistence can only be enabled
          // in one tab at a a time.
          // ...
      } else if (err.code === 'unimplemented') {
          // The current browser does not support all of the
          // features required to enable persistence
          // ...
      }
      console.log(err);
    });

    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        // User is signed in.
        console.log(user);
        setAuth(user);
        let lastUid = localStorage.getItem(LS_LAST_UID);
        localStorage.setItem(LS_LAST_UID, user.uid);
        // set current uid
        let uid = getUid(user);
        loadProjects(uid);
        loadTasks(uid);
        if (user && lastUid === LOCAL_UID) {
          // add local data to cloud
          copyDataFromLocalToCloud(user.uid);
        }
        // ...
      } else {
        // User is signed out.
        // ...
        setAuth(null);
        // check current uid
        let uid = getUid(null);
        loadProjects(uid);
        loadTasks(uid);
        console.log("user signed out");
      }
    });
  }, []);

  return (
      <div className="App" ref={rootRef}>
          { props.mobile ?
          <Grid container justify="flex-start" direction="column">
            <MobileHeader
              classes={classes} 
              searchValue={searchValue}
              showCompletedTasks={showCompletedTasks}
              onChangeSearchValue={handleOnChangeSearchValue}
              onClearSearchValue={handleClearSearchValue}
              onMenuShowCompletedTasks={handleShowCompletedTasks}
              onMenuHideCompletedTasks={handleHideCompletedTasks}
              />
            <List className={classes.mobileList}>
              {tasks && tasks.map((task, idx) => (
                checkShowTask(task, idx, null) && (
                  <Box key={task.id}>
                    {!isFirstInProject(tasks, task.id, null) &&
                      <Divider variant="inset" component="li" className={classes.listDivider}/>
                    }
                    <ListItem button className={classes.taskItem} id={task.id} onClick={handleEditOpen} onMouseEnter={handleTaskMouseOver} onMouseLeave={handleTaskMouseOut}>
                      <ListItemIcon className={classes.checkItem}>
                        <IconButton onClick={handleTaskDone}>
                          { isDone(task.data()) ?
                            <CheckCircleOutlineSharp className={ classes.mobileCheckBoxItem } />
                            : 
                            <PanoramaFishEyeSharp className={ classes.mobileCheckBoxItem } />
                          }
                        </IconButton>
                      </ListItemIcon>
                      { isDone(task.data()) ? 
                        <ListItemText primary={task.data().title} secondary={projectByID(task.data().projectId).title} classes= {{primary: classes.taskTextDone }} />
                      :
                        <ListItemText primary={task.data().title} secondary={projectByID(task.data().projectId).title} classes= {{primary: classes.taskText, secondary: classes.taskSecondaryText }} />
                      }
                      <ListItemSecondaryAction>
                        <Grid container direction="row" justify="flex-end" alignItems="center">
                      { task.data().dueDate && calcDueDateCircle(task) > 0 &&
                          <CircularProgress variant="static" value={calcDueDateCircle(task)} size="1rem" className={isTaskOverDue(task) ? classes.circProgressRed : classes.circProgress}/>
                      }
                      { task.data().remindDate && isOverReminderDate(task) &&
                          <NotificationsNoneSharp variant="static" size="1rem" className={isTaskOverDueRemind(task) ? classes.remindRed : classes.remind}/>
                      }
                      { task.data().series > 0 &&
                          <LoopOutlined variant="static" size="1rem" className={classes.remind}/>
                      }
                        </Grid>
                      </ListItemSecondaryAction>
                    </ListItem>
                  </Box>
                )
              ))}
            </List>
            <AppBar position="fixed" color="primary" className={classes.appBar}>
              <Toolbar>
                { 
                  auth ?
                    <Box span="true" className={classes.mobileMail}>{auth.email}</Box>
                    :
                    <Box span="true"></Box>
                }
                <Fab color="primary" onClick={handleAddMobile} aria-label="add" classes={{focusVisible: classes.fabButton, primary: classes.fabButton, secondary: classes.fabButton}} className={classes.fabButton}>
                  <Add />
                </Fab>
                <div className={classes.grow} />
                { 
                  auth ?
                    <Box span="true"><Link onClick={handleSignOut}>Sign Out</Link></Box>
                    :
                    <Box span="true"><Link onClick={handleOpenSignIn}>Log In</Link></Box>
                }
              </Toolbar>
            </AppBar>          
            <Grid container className="App-bottom-mobile">
              <Grid container className="App-about-mobile" alignItems="flex-start" justify="space-between" direction="row">
                <Grid item xs={3}>
                  <code>Ver.1.21</code>
                </Grid>
                <Grid item xs={9}>
                { 
                  auth ?
                    <Box span="true" textAlign="center">You work online: {auth.email} <Link onClick={handleSignOut}>Sign Out</Link></Box>
                    :
                    <Box span="true" textAlign="center"><Link onClick={handleOpenSignIn}>Log In</Link> to work online and have access to your tasks on all devices!</Box>
                }
                </Grid>
              </Grid>
            </Grid>

          </Grid>
          :
           <Grid container justify="flex-start" alignItems="stretch" direction="row">
            <AppHeader addProject={handleAddProjectOpen} 
              classes={{ header: classes.header, searchInput: classes.searchInput, hidden: classes.hidden, searchClear: classes.searchClear }}
              searchValue={searchValue}
              showCompletedTasks={showCompletedTasks}
              onChangeSearchValue={handleOnChangeSearchValue}
              onClearSearchValue={handleClearSearchValue}
              onMenuShowCompletedTasks={handleShowCompletedTasks}
              onMenuHideCompletedTasks={handleHideCompletedTasks}
            />
            <AppDivider classes={{ mainDivider: classes.mainDivider }}/>
            <Grid item xs={12} className={classes.gridListContainer}>
              <GridList className={classes.gridList} cols={14} spacing={30}>
                {projects && projects.map((project) => (
                  <GridListTile cols={4} key={project.id} className={classes.gridListTile}>
                    <Grid container direction="column">
                      <Grid id={project.id} container justify="space-between" className="App-project-header" onClick={handleEditProjectOpen}>
                        <Box className={classes.taskListHeader}>{project.data().title}</Box>
                        <Box><IconButton id={project.id} className={classes.taskAddIcon} onClick={handleAddOpen} color="primary"><Add/></IconButton></Box>
                      </Grid>
                      <List className={classes.taskList}>
                        {tasks && tasks.map((task, idx) => (
                          checkShowTask(task, idx, project) && (
                            <Box key={task.id}>
                              {!isFirstInProject(tasks, task.id, project.id) &&
                                <Divider variant="inset" component="li" className={classes.listDivider}/>
                              }
                              <ListItem button className={classes.taskItem} id={task.id} onClick={handleEditOpen} onMouseEnter={handleTaskMouseOver} onMouseLeave={handleTaskMouseOut}>
                                <ListItemIcon className={classes.checkItem}>
                                <Checkbox className={ task.id === focusedTask ? classes.checkBoxItem : classes.hidden }
                                    edge="start"
                                    tabIndex={-1}
                                    disableRipple
                                    onClick={handleTaskDone}
                                    checked={isDone(task.data())}
                                />
                                </ListItemIcon>
                                { idx === 0 && 
                                  <KeyboardArrowRightOutlined color="primary" className={classes.nextTaskIcon} />
                                }
                                { isDone(task.data()) ? 
                                  <ListItemText primary={task.data().title} classes= {{primary: classes.taskTextDone }} />
                                :
                                  <ListItemText primary={task.data().title} classes= {{primary: classes.taskText }} />
                                }
                                <ListItemSecondaryAction>
                                  <Grid container direction="row" justify="flex-end" alignItems="center">
                                { task.data().dueDate && calcDueDateCircle(task) > 0 &&
                                    <CircularProgress variant="static" value={calcDueDateCircle(task)} size="1rem" className={isTaskOverDue(task) ? classes.circProgressRed : classes.circProgress}/>
                                }
                                { task.data().remindDate && isOverReminderDate(task) &&
                                    <NotificationsNoneSharp variant="static" size="1rem" className={isTaskOverDueRemind(task) ? classes.remindRed : classes.remind}/>
                                }
                                { task.data().series > 0 &&
                                    <LoopOutlined variant="static" size="1rem" className={classes.remind}/>
                                }
                                  </Grid>
                                </ListItemSecondaryAction>
                              </ListItem>
                            </Box>
                          )
                        ))}
                      </List>
                    </Grid>
                  </GridListTile>
                ))}
              </GridList>
            </Grid>
            <Grid container className="App-bottom">
              <Grid item xs={12}>
                <Divider className={classes.mainDivider} />
              </Grid>
              <Grid container className="App-about" alignItems="flex-start" justify="space-between" direction="row">
                <Grid item xs={3}>
                  <code>Ver.1.21</code>
                </Grid>
                <Grid item xs={6}>
                { 
                  auth ?
                    <Box span="true" textAlign="center">You work online: {auth.email} <Link onClick={handleSignOut}>Sign Out</Link></Box>
                    :
                    <Box span="true" textAlign="center"><Link onClick={handleOpenSignIn}>Log In</Link> to work online and have access to your tasks on all devices!</Box>
                }
                </Grid>
                <Grid item xs={3}>
                  { window.location.href.indexOf("http") === 0 ?
                    <Box span="true" textAlign="right"><Link target="_blank" href="impressum.html">Impressum</Link></Box>
                  :  
                    <Box span="true" textAlign="right">{DateTime.local().toLocaleString()}</Box>
                  }
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          }
        <Modal
          container={rootRef.current}
          open={addEditOpen}
          onClose={handleAddEditClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          className={classes.modal}
          disableBackdropClick={true}
          >
            <Grid container className={props.mobile ? classes.paperProject : classes.paper} direction="row" cols={12}>
              <Grid item xs={12} className={classes.modalHeadline}>{editMode ? 'Edit' : 'Add'} Task</Grid>
              <Grid item sm={12} md={6}>
                <Grid container direction="column">
                  <form onSubmit={handleAddEditExecute}>
                    <TextField label="Title" variant="outlined" className="App-editTaskName" onChange={handleAddEditChangeName} value={addEditTask.title}/>
                    <TextField label="Description" variant="outlined" className="App-editTaskDesc" multiline={true} rows={props.mobile ? 3 : 9} onChange={handleAddEditChangeDesc} value={addEditTask.desc}/>
                  </form>
                </Grid>
              </Grid>
              <Grid item sm={12} md={6} className={props.mobile ? classes.editTaskBottomContainer : classes.editTaskRightContainer}>
                <Grid container direction="row" spacing={2} cols={12}>
                  <Grid item xs={6} className={classes.editAreaSliderLabel}>Prio</Grid>
                  <Grid item xs={6} className={classes.editAreaSliderLabel}>
                    <Grid container justify="flex-end" alignItems="flex-end">
                      <Box className={classes.editValueText}>{addEditPrioText}</Box>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} className={classes.editSlider}>
                    <Slider
                      defaultValue={3}
                      value={addEditTask.prio}
                      aria-labelledby="discrete-slider"
                      onChange={handlePrioOnChange}
                      step={1}
                      marks={true}
                      min={1}
                      max={5}
                    />
                  </Grid>
                  <Grid item xs={6} className={classes.editAreaSliderLabel}>Size</Grid>
                  <Grid item xs={6} className={classes.editAreaSliderLabel}>
                    <Grid container justify="flex-end" alignItems="flex-end">
                      <Box className={classes.editValueText}>{addEditSizeText}</Box>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} className={classes.editSlider}>
                    <Slider
                      defaultValue={3}
                      value={addEditTask.size}
                      aria-labelledby="discrete-slider"
                      onChange={handleSizeOnChange}
                      step={1}
                      marks={true}
                      min={1}
                      max={5}
                    />
                  </Grid>
                  <Grid item xs={6} className={classes.editAreaSliderLabel}>Series</Grid>
                  <Grid item xs={6} className={classes.editAreaSliderLabel}>
                    <Grid container justify="flex-end" alignItems="flex-end">
                      <Box className={classes.editValueText}>{addEditSeriesText}</Box>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} className={classes.editSlider}>
                    <Slider
                      defaultValue={0}
                      value={addEditTask.series}
                      aria-labelledby="discrete-slider"
                      onChange={handleSeriesOnChange}
                      step={1}
                      marks={true}
                      min={0}
                      max={8}
                    />
                  </Grid>
                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={de}>
                    <Grid item xs={12} className="App-dateTimePicker">
                      <DateTimePicker
                        clearable
                        label="Remind me"
                        format="dd.MM.yyyy HH:mm"
                        ampm={false}
                        value={addEditTask.remindDate}
                        onChange={handleRemindOnChange}
                      />
                    </Grid>
                    <Grid item xs={12} className="App-dateTimePicker">
                      <DateTimePicker
                        clearable
                        label="Due date"
                        format="dd.MM.yyyy HH:mm"
                        ampm={false}
                        value={addEditTask.dueDate}
                        onChange={handleDueOnChange}
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>
                </Grid>
              </Grid>
              <Grid container direction="row" justify="space-between" className={classes.modalButtonBar}>
                <Grid item xs={4}>
                  {editMode &&
                    <Button className={classes.deleteButton} onClick={handleAddEditDelete}>Delete</Button>
                  }
                </Grid>
                <Grid item xs={8}>
                  <Grid container direction="row" justify="flex-end">
                    <Button color="primary" onClick={handleAddEditExecute}>{editMode ? 'Update' : 'Add'}</Button>
                    <Button color="primary" onClick={handleAddEditClose}>Cancel</Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
        </Modal>
        <Modal
          container={rootRef.current}
          open={addEditProjectOpen}
          onClose={handleAddEditProjectClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          className={classes.modal}
          disableBackdropClick={true}
        >
            <Grid container className={classes.paperProject} direction="row" cols={12}>
              <Grid item xs={12} className={classes.modalHeadline}>{editProjectMode ? 'Edit' : 'Add'} Project</Grid>
              <Grid item xs={12}>
                <Grid container direction="column">
                  <form onSubmit={handleAddEditProjectExecute}>
                    <TextField label="Title" variant="outlined" className="App-editTaskName" onChange={handleAddEditProjectChangeName} value={addEditProject.title}/>
                    <TextField label="Description" variant="outlined" className="App-editTaskDesc" multiline={true} rows={9} onChange={handleAddEditProjectChangeDesc} value={addEditProject.desc}/>
                  </form>
                </Grid>
              </Grid>
              <Grid container direction="row" justify="space-between" className={classes.modalButtonBar}>
                <Grid item xs={4}>
                  {editProjectMode &&
                    <Button className={classes.deleteButton} onClick={handleAddEditProjectDelete}>Delete</Button>
                  }
                </Grid>
                <Grid item xs={8}>
                  <Grid container direction="row" justify="flex-end">
                    <Button color="primary" onClick={handleAddEditProjectExecute}>{editProjectMode ? 'Update' : 'Add'}</Button>
                    <Button color="primary" onClick={handleAddEditProjectClose}>Cancel</Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
        </Modal>
        <Modal
          container={rootRef.current}
          open={signInOpen}
          onClose={handleSignInClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          className={classes.modal}
          disableBackdropClick={false}
        >
            <Grid container className={classes.paper} direction="row" cols={12}>
              <Grid item xs={12} className={classes.modalHeadline}>{getRegistrationModeTitle()}</Grid>
              <Grid item xs={12}>
                <Grid container direction="column">
                  <form onSubmit={handleSignInSubmit}>
                    <TextField label="E-Mail" variant="outlined" className="App-editTaskName" onChange={handleSignInEmailChange} value={signInEmail} error={signInErrorMsg}/>
                    { registerMode !== FORGOT_PASSWORD_MODE && 
                      <TextField label="Password" type="password" variant="outlined" className="App-editTaskName" onChange={handleSignInPasswordChange} error={signInErrorMsg} value={signInPass}/>
                    }
                    { registerMode === REGISTRATION_MODE && 
                      <TextField label="Repeat Password" type="password" variant="outlined" className="App-editTaskName" onChange={handleSignInPasswordRepeatChange} error={signInErrorMsg} value={signInPassRepeat}/>
                    }
                    {signInErrorMsg && 
                      <FormHelperText id="component-error-text" className={classes.deleteButton}>{signInErrorMsg}</FormHelperText>
                    }
                    { registerMode === REGISTRATION_MODE &&
                      <Box>
                        <Checkbox edge="start" tabIndex={-1} disableRipple onClick={handleAcceptTerms}/>
                        <span>I accept the <Link target="_blank" href="/privacy_policy.html">Privacy Policy</Link> and the <Link target="_blank" href="/terms_conditions.html">Terms & Conditions.</Link></span>
                      </Box>
                    }
                    { registerMode === LOGIN_MODE &&
                      <Link onClick={handleForgotPasswordClick}>Forgot your password</Link>
                    }
                  </form>
                </Grid>
              </Grid>
              <Grid container direction="row" justify="space-between" className={classes.modalButtonBar}>
                <Grid item xs={5}>
                  { (registerMode === REGISTRATION_MODE || registerMode === FORGOT_PASSWORD_MODE) && 
                    <Button onClick={handleSwitchToSignIn}>Sign In</Button>
                  }
                  { registerMode === LOGIN_MODE && 
                    <Button onClick={handleSwitchToRegistration}>Create New Account</Button>
                  }
                </Grid>
                <Grid item xs={7}>
                  <Grid container direction="row" justify="flex-end">
                    { registerMode === REGISTRATION_MODE &&
                      <Button color="primary" onClick={handleRegister}>Sign Up</Button>
                    }
                    { registerMode === FORGOT_PASSWORD_MODE &&
                      <Button color="primary" onClick={handleResetPassword}>Reset Password</Button>
                    }
                    { registerMode === LOGIN_MODE &&
                      <Button color="primary" onClick={handleSignIn}>Log In</Button>
                    }
                    <Button color="primary" onClick={handleSignInClose}>Cancel</Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
        </Modal>
        <Modal
          container={rootRef.current}
          open={addMobileProjectSelection}
          onClose={handleAddMobileClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          className={classes.modal}
          disableBackdropClick={false}
        >
            <Grid container className={classes.paperProject} direction="row" cols={12}>
              <Grid item xs={12} className={classes.modalHeadline}>Select Project...</Grid>
              <Grid item xs={12}>
                { projects && projects.length > 0 ? 
                  <Grid container direction="column">
                    <List>
                      {projects && projects.map((project, idx) => (
                        <Box key={project.id}>
                          {idx !== 0 &&
                            <Divider variant="inset" component="li" className={classes.listDivider}/>
                          }
                          <ListItem button id={project.id} onClick={handleAddOpen}>
                            <ListItemText primary={project.data().title} />
                          </ListItem>
                        </Box>
                      ))}
                    </List>
                  </Grid>
                : 
                  <Grid container direction="column">
                    No projects available
                  </Grid>
                }
              </Grid>
              <Grid container direction="row" justify="space-between" className={classes.modalButtonBar}>
                <Grid item xs={5}>
                  <Button onClick={handleAddProjectOpen}>New Project</Button>
                </Grid>
                <Grid item xs={7}>
                  <Grid container direction="row" justify="flex-end">
                    <Button color="primary" onClick={handleAddMobileClose}>Cancel</Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
        </Modal>
      </div>
    
  );
}

export default function CuvellApp() {

  const theme = React.useMemo(
    () =>
      createMuiTheme({
        palette: {
          type: 'dark',
          primary: blue,
          secondary: teal
        },
      }),
    [],
  );

  const mobile = useMediaQuery(theme.breakpoints.down('xs'));

  console.log("Is mobile format: " + mobile);

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={3}>
        <App mobile={mobile}/>    
      </SnackbarProvider>
    </ThemeProvider>
  );
};
