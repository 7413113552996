import React, { useRef, useState } from 'react';
import { Button, Grid, IconButton, Input, InputAdornment, Menu, MenuItem} from "@material-ui/core";
import { Add, BusinessCenter, Clear, MoreVert, SearchOutlined } from "@material-ui/icons";

export default function AppHeader(props) {

    const searchInput = useRef(null);
    
    const [searchModeOn, setSearchModeOn] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    
    const contextMenuOpen = Boolean(anchorEl);

    const handleSearchModeOn = () => {
      setSearchModeOn(true);
      setTimeout(() => {
        searchInput.current.focus();
      }, 50);
    }

    const handleSearchModeOff = () => {
      setSearchModeOn(false);
      props.onClearSearchValue();
    }

    const handleContextMenuClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleContextMenuClose = () => {
      setAnchorEl(null);
    };

    const handleShowCompletedTasks = () => {
      handleContextMenuClose();
      props.onMenuShowCompletedTasks();
    };

    const handleHideCompletedTasks = () => {
      handleContextMenuClose();
      props.onMenuHideCompletedTasks();
    };

    return (
      <Grid container className={props.classes.header}>
        <Grid item xs={7} className="App-header-left">
          <code>Organise your tasks!</code><br/><br/>
          <code className="App-title">cuvell.com</code>
        </Grid>
        <Grid item xs={5}>
          <Grid container direction="column" justify="space-between">
            <Grid container direction="row" justify="flex-end" alignItems="flex-start">
              <Input inputRef={searchInput} 
                  placeholder="Search..." 
                  className={searchModeOn ? props.classes.searchInput : props.classes.hidden } 
                  inputProps={{ 'aria-label': 'description' }} 
                  value={props.searchValue}
                  onChange={props.onChangeSearchValue}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton onClick={handleSearchModeOff}>
                        <Clear className={props.classes.searchClear} />
                      </IconButton>
                    </InputAdornment>
                  }/>
              { !searchModeOn &&
                <IconButton onClick={handleSearchModeOn}>
                  <SearchOutlined className="App-icon" />
                </IconButton>
              }
              <IconButton onClick={handleContextMenuClick} >
                <MoreVert className="App-icon" />
              </IconButton>
              <Menu
                id="context-menu"
                anchorEl={anchorEl}
                keepMounted
                open={contextMenuOpen}
                onClose={handleContextMenuClose}
              >
                { props.showCompletedTasks ? 
                  <MenuItem key="show_done_tasks" onClick={handleHideCompletedTasks}>
                    Hide Completed Tasks
                  </MenuItem>
                :
                  <MenuItem key="show_done_tasks" onClick={handleShowCompletedTasks}>
                    Show Completed Tasks
                  </MenuItem>
                }
              </Menu>
            </Grid>
            <Grid container justify="flex-end" direction="row">
              <Button className="App-add-button" color="primary" onClick={props.addProject}><Add /> Project</Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  };