import React from 'react';
import { Divider, Grid } from "@material-ui/core";

export default function AppDivider(props) {

    return (
      <Grid item xs={12}>
        <Divider className={props.classes.mainDivider} />
      </Grid>
    )
  };