import { AppBar, Box, Grid, IconButton, Input, InputAdornment, Menu, MenuItem, Toolbar, Typography } from '@material-ui/core';
import { Clear, MoreVert, SearchOutlined } from '@material-ui/icons';
import React, { useRef, useState } from 'react';
import {ReactComponent as Logo} from './logo_white.svg';

export default function MobileHeader(props) {

    const searchInput = useRef(null);
    
    const [searchModeOn, setSearchModeOn] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    
    const contextMenuOpen = Boolean(anchorEl);

    const handleSearchModeOn = () => {
      setSearchModeOn(true);
      setTimeout(() => {
        searchInput.current.focus();
      }, 50);
    }

    const handleSearchModeOff = () => {
      setSearchModeOn(false);
      props.onClearSearchValue();
    }

    const handleContextMenuClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleContextMenuClose = () => {
      setAnchorEl(null);
    };

    const handleShowCompletedTasks = () => {
      handleContextMenuClose();
      props.onMenuShowCompletedTasks();
    };

    const handleHideCompletedTasks = () => {
      handleContextMenuClose();
      props.onMenuHideCompletedTasks();
    };

    return (
      <div>
        <AppBar position="static" color="transparent"><Toolbar></Toolbar></AppBar>
        <AppBar position="fixed" className={props.classes.mobileHeader}>
          <Toolbar>
            {searchModeOn ?
              <Grid container direction="row" alignItems="center">
                <Grid xs={11}>
                  <Grid container direction="row" justify="flex-start" alignItems="center">
                    <Logo className={props.classes.logo} />
                    <Input inputRef={searchInput} 
                      placeholder="Search..." 
                      className={searchModeOn ? props.classes.mobileSearchInput : props.classes.displayNone } 
                      inputProps={{ 'aria-label': 'description' }} 
                      value={props.searchValue}
                      onChange={props.onChangeSearchValue}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton onClick={handleSearchModeOff}>
                            <Clear className={props.classes.searchClear} />
                          </IconButton>
                        </InputAdornment>
                      }/>
                  </Grid>
                </Grid>
                <Grid xs={1}>
                  <Grid container direction="row" justify="flex-end" alignItems="center">
                    <IconButton onClick={handleContextMenuClick} >
                      <MoreVert className="" />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
              :
              <Grid container direction="row" alignItems="center">
                <Grid xs={8}>
                  <Grid container direction="row" justify="flex-start" alignItems="center">
                    <Logo className={props.classes.logo} />
                    <code className="App-title-mobile">cuvell.com</code>
                  </Grid>
                </Grid>
                <Grid xs={4}>
                  <Grid container direction="row" justify="flex-end" alignItems="center">
                    <IconButton onClick={handleSearchModeOn}>
                      <SearchOutlined className="App-icon" />
                    </IconButton>
                    <IconButton onClick={handleContextMenuClick} >
                      <MoreVert className="" />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            }
            <Menu
              id="context-menu"
              anchorEl={anchorEl}
              keepMounted
              open={contextMenuOpen}
              onClose={handleContextMenuClose}
            >
              { props.showCompletedTasks ? 
                <MenuItem key="show_done_tasks" onClick={handleHideCompletedTasks}>
                  Hide Completed Tasks
                </MenuItem>
              :
                <MenuItem key="show_done_tasks" onClick={handleShowCompletedTasks}>
                  Show Completed Tasks
                </MenuItem>
              }
            </Menu>
          </Toolbar>
        </AppBar>
        
      </div>
    )
  };